import { CSSProperties } from "react";
import { useMediaQuery } from 'react-responsive'

const Project = ({ title, link, stack, desc }: { title: string, link: string, stack: string[], desc: string }) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1284px)' });
  // this just HAS to be CSSProperties for some reason
  const h5Style: CSSProperties = isTabletOrMobile ? { float: "right", marginRight: 50 } : { float: "right", marginRight: 200 };
  const pStyle = isTabletOrMobile ? { marginRight: 30 } : { marginRight: 200 };
  const divStyle: CSSProperties = isTabletOrMobile ? { clear: "both", display: "flex", flexDirection: "column" } : { clear: "both" };

  return (
    <div>
      <div style={divStyle}>
        <h3 style={{ float: "left" }}>{link ? <a style={{ textDecoration: 'none' }} href={link}>{title}</a> : title}</h3>
        <h5 style={h5Style}>{stack.join(", ")}</h5>
      </div>
      <br /> {isTabletOrMobile ? <></> : <br />}
      <p style={pStyle}>{desc}</p>
      <br />
    </div>
  );
}

export default Project;